<template>
  <div class="d-flex justify-content-center">
    <b-card
      class="text-center"
      style="width: 70rem;"
    >
      <div class="demo-inline-spacing text-right">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-success"
          class="btn-icon rounded-circle"
          @click="valid"
        >
          <feather-icon icon="CheckIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-danger"
          class="btn-icon rounded-circle"
          @click="reject"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <b-tabs
        pills
        class="mt-2"
        nav-class="mb-3"
      >
        <b-tab
          title="Biographie"
          active
          style="text-align: start !important;"
        >
          <b-card-title>{{ getInformation[0].bio }}</b-card-title>
        </b-tab>
        <b-tab
          title="Success story"
          style="text-align: start !important;"
        >
          <div
            v-for="(item,index) in getInformation[0].story"
            :key="index"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                {{ item.title }}
              </h5>
              <small class="text-secondary">{{ item.dateDebut }} - {{ item.dateDebut }}</small>
            </div>
            <b-card-text class="mb-1">
              Description: {{ item.description }}
            </b-card-text>
          </div>
        </b-tab>
        <b-tab
          title="Formation"
          style="text-align: start !important;"
        >
          <div
            v-for="(item,index) in getInformation[0].formations"
            :key="index"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                {{ item.title }}
              </h5>
              <small class="text-secondary">{{ item.dateDebut }} - {{ item.dateDebut }}</small>
            </div>
            <b-card-text class="mb-1">
              Université: {{ item.universite }}
            </b-card-text>
            <small class="text-secondary">Pays: {{ item.pays }}</small>
          </div>
        </b-tab>
        <b-tab
          title="Experience"
          style="text-align: start !important;"
        >
          <div
            v-for="(item,index) in getInformation[0].experiences"
            :key="index"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                {{ item.title }}
              </h5>
              <small class="text-secondary">{{ item.dateDebut }} - {{ item.dateDebut }}</small>
            </div>
            <b-card-text class="mb-1">
              Description: {{ item.description }}
            </b-card-text>
          </div>
        </b-tab>
        <b-tab
          title="Publication"
          style="text-align: start !important;"
        >
          <div
            v-for="(item,index) in getInformation[0].publications"
            :key="index"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                {{ item.title }}
              </h5>
              <small class="text-secondary">Publié le {{ item.datePublication }}</small>
            </div>
            <b-card-text class="mb-1">
              Publié par {{ item.auteur }}
            </b-card-text>
            <small class="text-secondary">
              <a
                :href="item.lien"
                target="_blank"
              >
                Lire l'article
              </a>
            </small>
          </div>
        </b-tab>
        <b-tab
          title="Innovation"
          style="text-align: start !important;"
        >
          <div
            v-for="(item,index) in getInformation[0].innovations"
            :key="index"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                {{ item.title }}
              </h5>
            </div>
            <b-card-text class="mb-1">
              Description: {{ item.description }}
            </b-card-text>
            <small class="text-secondary">
              <a
                :href="item.lien"
                target="_blank"
              >
                Lier l'article
              </a>
            </small>
          </div>
        </b-tab>
        <b-tab
          title="Autre"
          style="text-align: start !important;"
        >
          <div
            v-for="(item,index) in getInformation[0].story"
            :key="index"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                {{ item.title }}
              </h5>
              <small class="text-secondary">{{ item.dateDebut }} - {{ item.dateDebut }}</small>
            </div>
            <b-card-text class="mb-1">
              Description: {{ item.description }}
            </b-card-text>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-modal
      v-model="open"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      :hide-footer="true"
      title="Rejet"
    >
      <validation-observer ref="loginValidation">
        <b-form @submit.prevent>
          <b-form-group label="Commentaire">
            <validation-provider
              #default="{ errors }"
              name="comment"
              rules="required"
            >
              <b-form-textarea
                id="comment"
                v-model="comment"
                rows="3"
                :state="errors.length > 0 ? false:null"
                placeholder="Votre commentaire"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            type="submit"
            variant="primary"
            block
            @click="validationForm"
          >
            <span v-if="loading === false"> Soumettre </span>
            <span v-else>
              <b-spinner small />
            </span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BModal, VBModal, BForm, BFormTextarea, BFormGroup, BSpinner, BCard, BTab, BTabs, BCardText, BCardTitle,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BCardText,
    BCardTitle,
    BButton,
    BModal,
    BForm,
    BFormTextarea,
    BSpinner,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      isAddNewUserSidebarActive: false,
      comment: '',
      open: false,
      loading: false,
      id: '',
    }
  },
  computed: {
    getInformation() {
      return this.$store.getters['informations/list']
    },
  },
  mounted() {
    this.$store.dispatch('informations/list', { params: { 'user.id': this.$router.currentRoute.params.id } })
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          this.loading = true
          await this.$store.dispatch('informations/update', {
            id: this.id,
            params: null,
            item: {
              comment: this.comment,
              isAccept: false,
            },
          }).then(async () => {
            await this.$store.dispatch('informations/list', { params: null })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Rejet effectuée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.loading = false
            this.comment = ''
            this.open = false
          }).catch(async () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est survenu. Veuillez recommencer ou actualiser la page',
                icon: 'ErrorIcon',
                variant: 'danger',
              },
            })
            this.loading = false
          })
        }
      })
    },
    valid() {
      this.$bvModal
        .msgBoxConfirm('Êtes-vous sur de vouloir valider cette demande ?', {
          centered: true,
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-secondary',
        })
        .then(async value => {
          console.log(value)
          if (value) {
            await this.$store.dispatch('informations/update', {
              id: this.getInformation[0].id,
              params: null,
              item: {
                isAccept: true,
              },
            }).then(async () => {
              await this.$store.dispatch('informations/list', { params: null })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Validation effectuée',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            }).catch(async () => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Une erreur est survenu. Veuillez recommencer ou actualiser la page',
                  icon: 'ErrorIcon',
                  variant: 'danger',
                },
              })
              this.loading = false
            })
          }
        })
    },
    reject() {
      this.id = this.getInformation[0].id
      this.open = true
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
